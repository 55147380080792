@font-face {
  font-family: "Din Pro Regular";
  src: url('/common/fonts/eot/DINPro-Regular.eot?t=1611217140103');
  /* IE9 */
  src: url('/common/fonts/eot/DINPro-Regular.eot?t=1611217140103#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/common/fonts/woff2/DINPro-Regular.woff2?t=1611217140103') format('woff2'), url('/common/fonts/woff/DINPro-Regular.woff?t=1611217140103') format('woff'), url('/common/fonts/ttf/DINPro-Regular.ttf?t=1611217140103') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */ url('/common/fonts/otf/DINPro-Regular.otf?t=1611217140103') format('opentype');
  /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
}
@font-face {
  font-family: "Din Pro Bold";
  src: url('/common/fonts/eot/DINPro-Bold.eot?t=1611217140103');
  /* IE9 */
  src: url('/common/fonts/eot/DINPro-Bold.eot?t=1611217140103#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/common/fonts/woff2/DINPro-Bold.woff2?t=1611217140103') format('woff2'), url('/common/fonts/woff/DINPro-Bold.woff?t=1611217140103') format('woff'), url('/common/fonts/ttf/DINPro-Bold.ttf?t=1611217140103') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */ url('/common/fonts/otf/DINPro-Bold.otf?t=1611217140103') format('opentype');
  /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
}
@font-face {
  font-family: "Din Pro Light";
  src: url('/common/fonts/eot/DINPro-Light.eot?t=1611217140103');
  /* IE9 */
  src: url('/common/fonts/eot/DINPro-Light.eot?t=1611217140103#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/common/fonts/woff2/DINPro-Light.woff2?t=1611217140103') format('woff2'), url('/common/fonts/woff/DINPro-Light.woff?t=1611217140103') format('woff'), url('/common/fonts/ttf/DINPro-Light.ttf?t=1611217140103') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */ url('/common/fonts/otf/DINPro-Light.otf?t=1611217140103') format('opentype');
  /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
}
@font-face {
  font-family: "Din Pro Medium";
  src: url('/common/fonts/eot/DINPro-Medium.eot?t=1611217140103');
  /* IE9 */
  src: url('/common/fonts/eot/DINPro-Medium.eot?t=1611217140103#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/common/fonts/woff2/DINPro-Medium.woff2?t=1611217140103') format('woff2'), url('/common/fonts/woff/DINPro-Medium.woff?t=1611217140103') format('woff'), url('/common/fonts/ttf/DINPro-Medium.ttf?t=1611217140103') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */ url('/common/fonts/otf/DINPro-Medium.otf?t=1611217140103') format('opentype');
  /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
}
@font-face {
  font-family: "Din Pro Black";
  src: url('/common/fonts/eot/DINPro-Black.eot?t=1611217140103');
  /* IE9 */
  src: url('/common/fonts/eot/DINPro-Black.eot?t=1611217140103#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/common/fonts/woff2/DINPro-Black.woff2?t=1611217140103') format('woff2'), url('/common/fonts/woff/DINPro-Black.woff?t=1611217140103') format('woff'), url('/common/fonts/ttf/DINPro-Black.ttf?t=1611217140103') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */ url('/common/fonts/otf/DINPro-Black.otf?t=1611217140103') format('opentype');
  /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
}
.App {
  text-align: center;
  font-family: "Din Pro Regular";
}
.top-container {
  background: url('./common/images/bg.jpg');
  background-size: cover;
  width: 100vw;
  height: 100vh;
  background-position: 50% 50%;
  color: #111;
  font-size: 2.4vmin;
}
.container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 100;
}
.container .mask {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
}
.container .mask .section,
.container .mask .section2 {
  position: absolute;
  height: 84vh;
  margin: 8vh 33vw;
  width: 34vw;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.container .mask .section .logo,
.container .mask .section2 .logo {
  background: url('./common/images/logo.png');
  width: 30%;
  height: 15%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
.container .mask .section .login-title,
.container .mask .section2 .login-title {
  font-size: 4vmin;
  font-family: "Din Pro Bold";
  color: #717070;
}
.container .mask .section .login-content,
.container .mask .section2 .login-content {
  margin: 5vh 6vw 6vh;
  line-height: 2.4vmin;
}
.container .mask .section .login-info,
.container .mask .section2 .login-info {
  width: calc(100% - 12vw);
  text-align: left;
}
.container .mask .section .login-info .login-account,
.container .mask .section2 .login-info .login-account,
.container .mask .section .login-info .login-password,
.container .mask .section2 .login-info .login-password {
  margin-bottom: 3.2vh;
  font-size: 2vmin;
}
.container .mask .section .login-info .login-account .login-placeholder,
.container .mask .section2 .login-info .login-account .login-placeholder,
.container .mask .section .login-info .login-password .login-placeholder,
.container .mask .section2 .login-info .login-password .login-placeholder {
  margin-bottom: 8px;
}
.container .mask .section .login-info .login-account .login-input-wraper,
.container .mask .section2 .login-info .login-account .login-input-wraper,
.container .mask .section .login-info .login-password .login-input-wraper,
.container .mask .section2 .login-info .login-password .login-input-wraper {
  width: 100%;
}
.container .mask .section .login-info .login-account .login-input-wraper input,
.container .mask .section2 .login-info .login-account .login-input-wraper input,
.container .mask .section .login-info .login-password .login-input-wraper input,
.container .mask .section2 .login-info .login-password .login-input-wraper input {
  width: calc(100% - 16px);
  border: none;
  outline: 1px solid #BABABA;
  height: 40px;
  line-height: 40px;
  color: #717070;
  padding: 0 8px;
  font-size: 1.8vmin;
}
.container .mask .section .login-info .login-account .error-message,
.container .mask .section2 .login-info .login-account .error-message,
.container .mask .section .login-info .login-password .error-message,
.container .mask .section2 .login-info .login-password .error-message,
.container .mask .section .login-info .login-account .error-message-2,
.container .mask .section2 .login-info .login-account .error-message-2,
.container .mask .section .login-info .login-password .error-message-2,
.container .mask .section2 .login-info .login-password .error-message-2,
.container .mask .section .login-info .login-account .error-message-3,
.container .mask .section2 .login-info .login-account .error-message-3,
.container .mask .section .login-info .login-password .error-message-3,
.container .mask .section2 .login-info .login-password .error-message-3,
.container .mask .section .login-info .login-account .error-message-4,
.container .mask .section2 .login-info .login-account .error-message-4,
.container .mask .section .login-info .login-password .error-message-4,
.container .mask .section2 .login-info .login-password .error-message-4,
.container .mask .section .login-info .login-account .error-message-5,
.container .mask .section2 .login-info .login-account .error-message-5,
.container .mask .section .login-info .login-password .error-message-5,
.container .mask .section2 .login-info .login-password .error-message-5 {
  position: absolute;
  font-size: 1.3vmin;
  margin-top: 2px;
  color: crimson;
  display: none;
}
.container .mask .section .login-btn,
.container .mask .section2 .login-btn {
  padding: 0 3rem;
  height: 40px;
  line-height: 40px;
  background-color: #BABABA;
  color: white;
  cursor: pointer;
  font-size: 1.8vmin;
  margin: 3.2vh auto;
}
.container .mask .section .forgot-password,
.container .mask .section2 .forgot-password,
.container .mask .section .go-back,
.container .mask .section2 .go-back {
  font-size: 1.6vmin;
  border-bottom: 1px solid #BABABA;
  cursor: pointer;
  padding: 4px 4px;
}
.forgot-pwd {
  display: none;
}
.disabled {
  pointer-events: none;
}
.footer {
  height: 8vh;
  position: absolute;
  bottom: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #BABABA;
  font-size: 1.4vmin;
  z-index: 99;
}
@media only screen and (max-width: 1200px) {
  .container .mask .section,
  .container .mask .section2 {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
  }
}
@media only screen and (max-width: 800px) {
  .container .mask .section,
  .container .mask .section2 {
    width: calc(100% - 30px);
    margin: 8vh 15px;
  }
  .container .mask .section .logo,
  .container .mask .section2 .logo {
    width: 50%;
  }
  .container .mask .section .login-title,
  .container .mask .section2 .login-title {
    font-size: 1.8rem;
  }
  .container .mask .section .login-content,
  .container .mask .section2 .login-content {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
  .container .mask .section .login-info .login-account,
  .container .mask .section2 .login-info .login-account,
  .container .mask .section .login-info .login-password,
  .container .mask .section2 .login-info .login-password {
    font-size: 4vmin;
  }
  .container .mask .section .login-info .login-account .login-input-wraper input,
  .container .mask .section2 .login-info .login-account .login-input-wraper input,
  .container .mask .section .login-info .login-password .login-input-wraper input,
  .container .mask .section2 .login-info .login-password .login-input-wraper input {
    font-size: 3.6vmin;
  }
  .container .mask .section .login-info .login-account .error-message,
  .container .mask .section2 .login-info .login-account .error-message,
  .container .mask .section .login-info .login-password .error-message,
  .container .mask .section2 .login-info .login-password .error-message,
  .container .mask .section .login-info .login-account .error-message-2,
  .container .mask .section2 .login-info .login-account .error-message-2,
  .container .mask .section .login-info .login-password .error-message-2,
  .container .mask .section2 .login-info .login-password .error-message-2,
  .container .mask .section .login-info .login-account .error-message-3,
  .container .mask .section2 .login-info .login-account .error-message-3,
  .container .mask .section .login-info .login-password .error-message-3,
  .container .mask .section2 .login-info .login-password .error-message-3,
  .container .mask .section .login-info .login-account .error-message-4,
  .container .mask .section2 .login-info .login-account .error-message-4,
  .container .mask .section .login-info .login-password .error-message-4,
  .container .mask .section2 .login-info .login-password .error-message-4,
  .container .mask .section .login-info .login-account .error-message-5,
  .container .mask .section2 .login-info .login-account .error-message-5,
  .container .mask .section .login-info .login-password .error-message-5,
  .container .mask .section2 .login-info .login-password .error-message-5 {
    font-size: 2.6vmin;
  }
  .container .mask .section .login-btn,
  .container .mask .section2 .login-btn {
    font-size: 4vmin;
  }
  .container .mask .section .forgot-password,
  .container .mask .section2 .forgot-password,
  .container .mask .section .go-back,
  .container .mask .section2 .go-back {
    font-size: 4vmin;
  }
  .footer {
    font-size: 2.8vmin;
  }
}
