
@font-face {
	font-family: "Din Pro Regular";
  src: url('/common/fonts/eot/DINPro-Regular.eot?t=1611217140103'); /* IE9 */
	src: url('/common/fonts/eot/DINPro-Regular.eot?t=1611217140103#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/common/fonts/woff2/DINPro-Regular.woff2?t=1611217140103') format('woff2'),
	url('/common/fonts/woff/DINPro-Regular.woff?t=1611217140103') format('woff'),
	url('/common/fonts/ttf/DINPro-Regular.ttf?t=1611217140103') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
	url('/common/fonts/otf/DINPro-Regular.otf?t=1611217140103') format('opentype'); /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
}

@font-face {
	font-family: "Din Pro Bold";
  src: url('/common/fonts/eot/DINPro-Bold.eot?t=1611217140103'); /* IE9 */
	src: url('/common/fonts/eot/DINPro-Bold.eot?t=1611217140103#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/common/fonts/woff2/DINPro-Bold.woff2?t=1611217140103') format('woff2'),
	url('/common/fonts/woff/DINPro-Bold.woff?t=1611217140103') format('woff'),
	url('/common/fonts/ttf/DINPro-Bold.ttf?t=1611217140103') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
	url('/common/fonts/otf/DINPro-Bold.otf?t=1611217140103') format('opentype'); /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
}

@font-face {
	font-family: "Din Pro Light";
	src: url('/common/fonts/eot/DINPro-Light.eot?t=1611217140103'); /* IE9 */
	src: url('/common/fonts/eot/DINPro-Light.eot?t=1611217140103#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/common/fonts/woff2/DINPro-Light.woff2?t=1611217140103') format('woff2'),
	url('/common/fonts/woff/DINPro-Light.woff?t=1611217140103') format('woff'),
	url('/common/fonts/ttf/DINPro-Light.ttf?t=1611217140103') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
	url('/common/fonts/otf/DINPro-Light.otf?t=1611217140103') format('opentype'); /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
}

@font-face {
	font-family: "Din Pro Medium";
	src: url('/common/fonts/eot/DINPro-Medium.eot?t=1611217140103'); /* IE9 */
	src: url('/common/fonts/eot/DINPro-Medium.eot?t=1611217140103#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/common/fonts/woff2/DINPro-Medium.woff2?t=1611217140103') format('woff2'),
	url('/common/fonts/woff/DINPro-Medium.woff?t=1611217140103') format('woff'),
	url('/common/fonts/ttf/DINPro-Medium.ttf?t=1611217140103') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
	url('/common/fonts/otf/DINPro-Medium.otf?t=1611217140103') format('opentype'); /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
}

@font-face {
	font-family: "Din Pro Black";
	src: url('/common/fonts/eot/DINPro-Black.eot?t=1611217140103'); /* IE9 */
	src: url('/common/fonts/eot/DINPro-Black.eot?t=1611217140103#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/common/fonts/woff2/DINPro-Black.woff2?t=1611217140103') format('woff2'),
	url('/common/fonts/woff/DINPro-Black.woff?t=1611217140103') format('woff'),
	url('/common/fonts/ttf/DINPro-Black.ttf?t=1611217140103') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
	url('/common/fonts/otf/DINPro-Black.otf?t=1611217140103') format('opentype'); /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
}
